@use 'mixins' as *;
.accordion {
  --accordion-trigger-padding: 24px;
  --accordion-indicator-size: 20px;
  --accordion-indicator-rotation: -45deg;
}

.partner-login {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-xsmall);
  text-decoration: none;
  font-size: var(--font-weight-regular);

  &.hide {
    display: none;
  }
}

.partner-login-link {
  width: 100%;
  display: flex;
  gap: var(--spacing-xsmall);
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  font-size: var(--fontSizes-body);
  font-weight: normal;
  color: var(--color-solid-black);
}

.search-wrapper {
  padding: var(--spacing-small) 0 var(--spacing-medium) 0;
}

.menu-wrapper {
  min-height: 78%;

  &.hide {
    display: none;
  }
}

.menu-link {
  display: block;
  text-decoration: none;
  min-height: 32px;
  color: var(--color-solid-black);
  padding-block: var(--spacing-small) var(--spacing-regular);
  border-bottom: thin solid var(--color-gray3);
  font-family: var(--font-helvetica-neue-bold);
  font-size: var(--font-size-header-3-content);

  &:hover {
    color: var(--color-solid-black);
  }
}

.menu-nested-sub-link {
  display: block;
  text-decoration: none;
  color: var(--color-solid-black);
  padding-block: var(--spacing-xsmall);
  border-top: thin solid var(--color-gray3);
  font-size: 1rem !important;
  font-family: var(--font-helvetica-neue-bold);
}

.menu-sub-link {
  display: block;
  text-decoration: none;
  min-height: 44px;
  color: var(--color-solid-black);
  padding-block: var(--spacing-small) var(--spacing-small);
  font-family: var(--font-league-gothic);
  font-size: var(--font-size-header-4);
  text-transform: uppercase;
}

.sub-accordion-item {
  display: block;
  text-decoration: none;
  margin-top: 8px;
  min-height: 44px;
  color: var(--color-solid-black);
  padding-block: var(--spacing-regular) var(--spacing-small);
  font-family: var(--font-league-gothic);
  font-size: var(--fontSizes-header4);
  line-height: var(--lineHeights-header4);
  text-transform: uppercase;

  div {
    padding-inline-start: 0 !important;
  }
}

.accordion-content {
  padding-bottom: 0px;
}
.accordion-label {
  line-height: var(--line-height-header-4);
  font-family: var(--font-helvetica-neue-bold);
  font-size: var(--font-size-header-3-content);
}

.accordion-content {
  padding-bottom: 0px;
}

.accordion-trigger {
  inline-size: 100%;
  padding-block: calc(var(--accordion-trigger-padding, 1.5rem) - 0.5rem)
    var(--accordion-trigger-padding, 1.5rem);
}
